<template>
  <div>
    <div id="pageEditEpisode" class="mainContents">
      <UserNavSp />
      <UserTitle :user="user" />
      <UserNav :user="user" />

      <EpisodeForm ref="episodeForm" :prop-episode="e" />
    </div>
  </div>
</template>

<script>
import UserTitle from '@/components/UserTitle.vue'
import UserNav from '@/components/UserNav.vue'
import UserNavSp from '@/components/UserNavSp.vue'
import EpisodeForm from '@/components/EpisodeForm.vue'
import { authComputed } from '@/store/helpers.js'

export default {
  components: {
    EpisodeForm,
    UserTitle,
    UserNav,
    UserNavSp
  },
  props: {
    e: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      user: {}
    }
  },
  computed: {
    ...authComputed
  },
  beforeDestroy () {},
  created: function () {
    this.user = this.$store.getters.getLoggedInUser
  },
  mounted () {}
}
</script>

<style scoped>
.field {
  margin-bottom: 24px;
}
</style>
